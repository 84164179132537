/* eslint-disable */
export const getFuelGraph = (params = null) => {
  return {
    query: `
        query Fuels($fuelsId: String, $name: String) {
            Fuels(id: $fuelsId, name: $name) {
            isSuccess
            message
            result {
                _id
                name
            }
            error
            }
        }`,
    ...(params && { variables: params })
  };
};
