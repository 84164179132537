/* eslint-disable */
export const getSummaryGraph = (params = null) => {
  return {
    query: `
    query Summary($startDate: String, $endDate: String, $sellerId: String!) {
      Summary(startDate: $startDate, endDate: $endDate, sellerId: $sellerId) {
        result {
          chart {
            amount
            month
          }
          clientsAmount
          profits
          reservations
          percentage {
            sellerId
            percentage
            name
            amount
          }
          owners {
            _id
            name
            email
            number
            dateTime
            amountToPay
            totalPrice
          }
          myTotal
        }
        message
        isSuccess
      }
    }`,
    ...(params && { variables: params })
  };
};
