// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDYphTm53WY6Uxsx38vs6mWYjkCqYcqT3A',
  authDomain: 'rent-car-e4bd0.firebaseapp.com',
  projectId: 'rent-car-e4bd0',
  storageBucket: 'rent-car-e4bd0.appspot.com',
  messagingSenderId: '9271167692',
  appId: '1:9271167692:web:83777d71415d9a5411d069'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
