import * as React from 'react';

const EditIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill={props?.color || '#000'}
      d="M22 7.24a1 1 0 0 0-.29-.71l-4.24-4.24a1 1 0 0 0-.71-.29 1 1 0 0 0-.71.29l-2.83 2.83L2.29 16.05a1 1 0 0 0-.29.71V21a1 1 0 0 0 1 1h4.24a1 1 0 0 0 .76-.29l10.87-10.93L21.71 8a1.19 1.19 0 0 0 .22-.33 1 1 0 0 0 0-.24.7.7 0 0 0 0-.14ZM6.83 20H4v-2.83l9.93-9.93 2.83 2.83ZM18.17 8.66l-2.83-2.83 1.42-1.41 2.82 2.82Z"
    />
  </svg>
);
export default EditIcon;
