/* eslint-disable */
export const getTransmissionGraph = (params = null) => {
  return {
    query: `
        query Transmission($transmissionId: String, $name: String) {
            Transmission(id: $transmissionId, name: $name) {
            isSuccess
            message
            result {
                _id
                name
            }
            error
            }
        }`,
    ...(params && { variables: params })
  };
};
