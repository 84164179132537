import { useEffect, useState } from 'react';
import Loader from '../../Loader';
import './styles.css';
import { roles } from '../../../constants/system';
const Form = ({ onSubmit, clientData, isLoading = false, editModal }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    license: '',
    passport: '',
    idDocument: '',
    residenceAddress: '',
    address: '',
    rolId: roles.client
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  useEffect(() => {
    if (clientData) {
      setFormData({ ...clientData, rolId: roles.client });
    }
  }, [clientData]);

  return (
    <form className="custom-form" onSubmit={handleSubmit}>
      <div className="formTitleWrapper">
        <h2>{editModal ? 'Editar Cliente' : 'Agregar Cliente'}</h2>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="name">
          Nombre
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input"
            placeholder="Juan Peréz"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Correo
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="input"
            placeholder="juan.perez@gmail.com"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Número
          <input
            type="text"
            id="phone"
            name="number"
            value={formData.number}
            onChange={handleChange}
            className="input"
            placeholder="7878 6296"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Licencia
          <input
            type="text"
            id="license"
            name="license"
            value={formData.license}
            onChange={handleChange}
            className="input"
            placeholder="09876780"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Documento de indentidad
          <input
            type="text"
            id="idDocument"
            name="idDocument"
            value={formData.idDocument}
            onChange={handleChange}
            className="input"
            placeholder="068594628-0"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Número de pasaporte
          <input
            type="text"
            id="passport"
            name="passport"
            value={formData.passport}
            onChange={handleChange}
            className="input"
            placeholder="786239191-0"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Dirección de residencia
          <input
            type="text"
            id="residenceAddress"
            name="residenceAddress"
            value={formData.residenceAddress}
            onChange={handleChange}
            className="input"
            placeholder="Brk New Orlands 12-b"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="model">
          Dirección de domicilio
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="input"
            placeholder="Altos de Miramonte, calle sisimiles, casa 2E"
          />
        </label>
      </div>

      <div className="buttonsWrapper">
        <button
          type="submit"
          className="cardSecondaryButton"
          disabled={!formData.name || !formData.email}
        >
          {isLoading ? <Loader stroke="#fff" /> : <span>Guardar</span>}
        </button>
      </div>
    </form>
  );
};

export default Form;
