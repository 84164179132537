import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

// const baseURL = 'https://carrental-9ijm.onrender.com';
// eslint-disable-next-line no-undef
const baseURL = 'https://rent-car-backend-ecxw.onrender.com/api';

// Async thunk for user login
export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, passsword }) => {
    try {
      const response = await axios.post(`${baseURL}/auth`, {
        email: email.toLowerCase(),
        password: passsword
      });

      return response?.data?.result;
    } catch (error) {
      if (error.response.data.message == 'Password-failed') {
        toast.error('Contraseña incorrecta');
      }
      if (error.response.data.message == `User doesn't exist`) {
        toast.error('El usuario no existe');
      }
      throw new Error(error.response.data.errors || 'Login failed');
    }
  }
);
export const sendFile = createAsyncThunk('user/upload', async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${baseURL}/user/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    toast.success(`Archivo subido con exito`);
    return response;
  } catch (error) {
    toast.error(error.message);
    throw new Error(error.response.data.errors);
  }
});

// Async thunk for user registration
export const registerUser = createAsyncThunk(
  'auth/register',
  async ({ name, email, password, passwordConfirmation }) => {
    try {
      const response = await axios.post(`${baseURL}/users/signup`, {
        user: {
          fullname: name,
          email,
          password,
          password_confirmation: passwordConfirmation
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.errors || 'Registration failed');
    }
  }
);

export const logOutUser = createAsyncThunk('user/logout', async () => {
  try {
    localStorage.clear();
    return {
      message: 'Logged out'
    };
  } catch (error) {
    throw new Error(error.response.data.message || 'Logout failed');
  }
});

const initialState = {
  status: 'idle',
  id: null,
  name: '',
  picture: '',
  email: '',
  passsword: '',
  error: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Add a reducer to reset status after a certain time
    resetStatus: (state) => ({
      ...state,
      status: 'idle'
    })
  },
  extraReducers(builder) {
    builder
      .addCase(registerUser.pending, (state) => ({
        ...state,
        status: 'loading'
      }))
      .addCase(registerUser.fulfilled, (state, action) => ({
        ...state,
        status: 'signed up successfully',
        name: action.payload.name,
        email: action.payload.email,
        id: action.payload._id
      }))
      .addCase(registerUser.rejected, (state, action) => ({
        ...state,
        status: 'error',
        error: action.error.message
      }))
      .addCase(loginUser.pending, (state) => ({
        ...state,
        status: 'loading'
      }))
      .addCase(loginUser.fulfilled, (state, action) => {
        toast.success(`Bienvenido al sistema ${action?.payload?.name}!`);
        return {
          ...state,
          status: 'logged in successfully',
          name: action.payload.name,
          email: action.payload.email,
          id: action.payload._id,
          rolId: action.payload.rolId
        };
      })
      .addCase(loginUser.rejected, (state, action) => ({
        ...state,
        status: 'error',
        error: action.error.message
      }))
      .addCase(logOutUser.fulfilled, (state, action) => {
        toast.success('Regresa pronto!');

        return {
          ...state,
          status: action.payload.message,
          name: '',
          email: '',
          id: null
        };
      })
      .addCase(logOutUser.rejected, (state, action) => ({
        ...state,
        status: 'error',
        error: action.error.message
      }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => ({
          ...state,
          status: 'error',
          error: action.error.message || 'Request failed'
        })
      );
  }
});

export const { resetStatus } = authSlice.actions;
export default authSlice.reducer;
