import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchCars } from '../../redux/carsSlice';
import { fetchReservationOrders } from '../../redux/reservationOrdersSlice';
import { FaRegFilePdf } from 'react-icons/fa6';
import {
  addReturnOrders,
  closeModal,
  fetchReturnOrders,
  openModal
} from '../../redux/returnOrdersSlice';
import '../../styles/detail.css';
import Alert from '../Alert';
import AppModal from '../Modal';
import OrderReturnForm from './OrderReturnForm/OrderReturnForm';

const ReservedDetail = () => {
  const { id } = useParams();
  const status = useSelector((state) => state.reservationOrder.status);
  const isLoading = useSelector((state) => state.reservationOrder.isLoading);
  const orders = useSelector((state) => state.reservationOrder.orders);
  const returnOrders = useSelector((state) => state.returnOrder.orders);

  const returnOrder = returnOrders?.find((order) => order?.orderId?._id === id);

  const receiver = returnOrder?.receivedBy;
  const isModalOpen = useSelector((state) => state.returnOrder.isModalOpen);
  const isOrderReturnLoading = useSelector(
    (state) => state.returnOrder.isLoading
  );
  const orderReturnStatus = useSelector((state) => state.returnOrder.status);
  const order = orders?.find((order) => order._id === id);
  const car = order?.vehicleId;
  const seller = order?.sellerId;
  const client = order?.clientId;
  const driver = order?.driverId;
  const startDate = new Date(parseInt(order?.startDate));
  const endDate = new Date(parseInt(order?.endDate));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchCars());
    dispatch(fetchReservationOrders());
    dispatch(fetchReturnOrders());
  }, [dispatch]);

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const navigateToEdit = () => {
    navigate(`/reserved/${id}/edit`, { replace: true });
  };

  const handleSubmitReturnOrder = (returnOrder) => {
    dispatch(addReturnOrders({ ...returnOrder, orderId: id }));
  };

  const handleDelete = () => {};

  const handleOpenDriverModal = () => {
    setIsDriverModalOpen(true);
  };

  const handleGenerateContract = () => {
    window.open(
      `/reserved/${id}/contract`,
      '_blank',
      'rel=noopener noreferrer'
    );
  };

  if (!order) {
    return <h1 className="heading">Loading Detail...Please Wait</h1>;
  }

  return (
    <>
      <section id="detail">
        <header className="header">
          <IoIosArrowBack className="back-btn" onClick={() => navigate(-1)} />
          <h2 className="header-title">{car.brand}</h2>
        </header>

        <div className="detail-container">
          <div className="banner">
            <div className="img-container">
              <img src={car.imgCar} alt={car.brand} className="img" />
            </div>
          </div>

          <div className="detail-wrapper">
            <div className="details">
              <div className="features-table">
                <div className="feature-cell bold">
                  <span>Vendedor</span>
                </div>
                <div className="feature-cell">
                  <span>{seller?.name}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Cliente</span>
                </div>
                <div className="feature-cell">
                  <span>{client?.name}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Placa del carro</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.idCar}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Marca</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.brand}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Estado</span>
                </div>
                <div className="feature-cell">
                  <span>Reservado</span>
                </div>
                <div className="feature-cell bold">
                  <span>Fecha de inicio</span>
                </div>
                <div className="feature-cell">
                  <span>
                    {`${startDate?.getDate()}/${
                      startDate?.getMonth() + 1
                    }/${startDate?.getFullYear()}`}
                  </span>
                </div>
                <div className="feature-cell bold">
                  <span>Fecha de entrega</span>
                </div>
                <div className="feature-cell">
                  <span>
                    {`${endDate?.getDate()}/${
                      endDate?.getMonth() + 1
                    }/${endDate?.getFullYear()}`}
                  </span>
                </div>
                <div className="feature-cell bold">
                  <span>Depósito</span>
                </div>
                <div className="feature-cell">
                  <span>${order?.deposit}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Costos Extras</span>
                </div>
                <div className="feature-cell">
                  <span>${order?.amount}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Descripción de costos extra</span>
                </div>
                <div className="feature-cell">
                  <span>{order?.amountDescription}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Contrato</span>
                </div>
                <div className="feature-cell">
                  {order?.pdfReference && (
                    <a
                      href={order?.pdfReference}
                      download={`${client?.name}_contrato.pdf`}
                    >
                      <span
                        style={{
                          color: 'blue',
                          background: '#F94C67',
                          padding: '0.3rem 0.5rem',
                          borderRadius: '10px',
                          fontSize: '1rem',
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.2rem'
                        }}
                      >
                        <FaRegFilePdf />

                        {`contrato_${client?.name || ''}.pdf`}
                      </span>
                    </a>
                  )}
                </div>
                <div className="feature-cell bold">
                  <span>Conductor designado</span>
                </div>
                <div className="feature-cell">
                  <button
                    style={{ background: 'none' }}
                    onClick={handleOpenDriverModal}
                  >
                    <span style={{ color: 'blue' }}>{driver?.name}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="reserve-container">
              <button
                type="button"
                className="reserve-btn"
                onClick={navigateToEdit}
              >
                Editar
              </button>
              <button
                type="button"
                className="reserve-btn"
                onClick={handleGenerateContract}
              >
                Generar Contrato
              </button>
              {!returnOrder && (
                <button
                  type="button"
                  className="reserve-btn"
                  onClick={handleOpenModal}
                >
                  Entregar
                </button>
              )}
            </div>
          </div>
        </div>

        {returnOrder && (
          <>
            <h2 className="section-title" style={{ color: '#000' }}>
              Reporte de retorno
            </h2>
            <div className="detail-container">
              {returnOrder?.imgReference && (
                <div className="banner">
                  <div className="img-container">
                    <img
                      src={returnOrder?.imgReference}
                      alt={returnOrder?.comments}
                      className="img"
                    />
                  </div>
                </div>
              )}

              <div className="detail-wrapper">
                <div className="details">
                  <div className="features-table">
                    <div className="feature-cell bold">
                      <span>Recibido por</span>
                    </div>
                    <div className="feature-cell">
                      <span>{receiver?.name}</span>
                    </div>
                    <div className="feature-cell bold">
                      <span>Odometro</span>
                    </div>
                    <div className="feature-cell">
                      <span>{returnOrder?.odometer}</span>
                    </div>
                    <div className="feature-cell bold">
                      <span>Gas</span>
                    </div>
                    <div className="feature-cell">
                      <span>{returnOrder?.gasReturn}</span>
                    </div>
                    <div className="feature-cell bold">
                      <span>Comentarios</span>
                    </div>
                    <div className="feature-cell">
                      <span>{returnOrder?.comments}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
      {isModalOpen && (
        <AppModal onClose={handleCloseModal}>
          <OrderReturnForm
            onSubmit={handleSubmitReturnOrder}
            isLoading={orderReturnStatus === 'adding' && isOrderReturnLoading}
          />
        </AppModal>
      )}
      {isDriverModalOpen && (
        <AppModal onClose={() => setIsDriverModalOpen(false)}>
          <div className="detail-wrapper" style={{ width: '100%' }}>
            <h3>Información de conductor</h3>

            <div className="details">
              <div className="features-table">
                <div className="feature-cell bold">
                  <span>Conductor designado</span>
                </div>
                <div className="feature-cell">
                  <span>{driver?.name}</span>
                </div>

                <div className="feature-cell bold">
                  <span>Licencia</span>
                </div>
                <div className="feature-cell">
                  <span>{driver?.license}</span>
                </div>

                <div className="feature-cell bold">
                  <span>Documento de identidad</span>
                </div>
                <div className="feature-cell">
                  <span>{driver?.idDocument}</span>
                </div>
              </div>
            </div>
          </div>
        </AppModal>
      )}
      {Boolean(isAlertVisible) && (
        <Alert
          title="Estás seguro que quieres eliminar este auto?"
          description="Esta acción es irreversible"
          type="delete"
          onCloseAlert={() => setIsAlertVisible(false)}
          primaryAction={() => console.log('primary action')}
          isLoading={isLoading && status === 'deleting'}
        />
      )}
    </>
  );
};

export default ReservedDetail;
