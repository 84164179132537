import EditIcon from './EditIcon';
import ExitIcon from './ExitIcon';
import SendIcon from './SendIcon';
import TrashIcon from './TrashIcon';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Icon = ({ name, width = 24, height = 24, ...iconParams }) => {
  return iconSelector(name, { ...iconParams, width, height });
};

const iconSelector = (name, iconParams) => {
  switch (name) {
    case 'edit':
      return <EditIcon {...iconParams} />;
    case 'delete':
      return <TrashIcon {...iconParams} />;
    case 'exit':
      return <ExitIcon {...iconParams} />;
    case 'send':
      return <SendIcon {...iconParams} />;
    case 'eye':
      return <VisibilityIcon {...iconParams} />;
    default:
      return <EditIcon {...iconParams} />;
  }
};

export default Icon;
