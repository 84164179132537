import React, { useEffect, useState } from 'react';
import {
  AiFillCar,
  AiOutlineCalendar,
  AiOutlineTeam,
  AiOutlineContacts,
  AiFillDashboard
} from 'react-icons/ai';
import { FaFacebookF, FaWhatsapp } from 'react-icons/fa';
import { MdLogin, MdLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import logo1 from '../assets/logo.png';
import { logOutUser } from '../redux/authSlice';
import '../styles/navbar.css';
import { roles } from '../constants/system';

const Navbar = () => {
  const userData = JSON.parse(localStorage.getItem('user'));
  const [isMenuActive, setIsMenuActive] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const userLogged = useSelector((state) => state.auth);

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
  };

  const handleHamburgerKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleHamburgerClick();
    }
  };

  const handleLogoutClick = () => {
    dispatch(logOutUser());
    localStorage.clear();
    window.location.reload();

    setIsMenuActive(false);
  };

  return (
    <>
      {/* navbar */}
      <nav className="navbar">
        <div className="nav">
          <div
            id="hamburger_menu"
            className={`hamburger_menu ${isMenuActive ? 'active' : ''}`}
            onClick={handleHamburgerClick}
            onKeyDown={handleHamburgerKeyDown}
            role="button"
            tabIndex={0}
          >
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
          <div className="nav-logo-container">
            <div className={`nav-logo ${isMenuActive ? 'active' : ''}`}>
              <img src={logo1} alt="logo" className="nav-logo-img" />
            </div>
            <h2 className="mainTitle">HG Rent A Car</h2>
          </div>
        </div>

        {userData ? (
          <div className="nav-links">
            <Link className="nav-link" to="/" onClick={handleLogoutClick}>
              Salir
            </Link>
          </div>
        ) : (
          <div className="nav-links">
            <div className="ver-line" />
            <Link className="nav-link" to="/signin">
              Log In
            </Link>
          </div>
        )}
      </nav>

      {/* nav menu */}
      <nav className={`nav_menu_container ${isMenuActive ? 'active' : ''}`}>
        <div className="nav_menu">
          <Link className="logo" to="/">
            <img src={logo1} alt="logo" className="logo-img" />
          </Link>
          <ul className="menu_links_wrapper">
            <li>
              <Link
                className={`menu_link ${
                  location.pathname === '/dashboard' ? 'activeLink' : null
                }`}
                to={userData ? '/dashboard' : '/signin'}
              >
                <AiFillDashboard className="nav-icon" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                className={`menu_link ${
                  location.pathname === '/' ? 'activeLink' : null
                }`}
                to="/"
              >
                <AiFillCar className="nav-icon" />
                Carros disponibles
              </Link>
            </li>
            {userLogged.rolId !== roles.seller && (
              <>
                <li>
                  <Link
                    className={`menu_link ${
                      location.pathname === '/sellers' ? 'activeLink' : null
                    }`}
                    to={userData ? '/usuarios' : '/signin'}
                  >
                    <AiOutlineTeam className="nav-icon" />
                    Usuarios
                  </Link>
                </li>
                <li>
                  <Link
                    className={`menu_link ${
                      location.pathname === '/sellers' ? 'activeLink' : null
                    }`}
                    to={userData ? '/dragzone' : '/signin'}
                  >
                    <AiOutlineTeam className="nav-icon" />
                    Importar Usuarios
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link
                className={`menu_link ${
                  location.pathname === '/clients' ? 'activeLink' : null
                }`}
                to={userData ? '/clients' : '/signin'}
              >
                <AiOutlineContacts className="nav-icon" />
                Clientes
              </Link>
            </li>
            <li>
              <Link
                className={`menu_link ${
                  location.pathname === '/favourites' ? 'activeLink' : null
                }`}
                to={userData ? '/reserved' : '/signin'}
              >
                <AiOutlineCalendar className="nav-icon" />
                Reservaciones
              </Link>
            </li>
            <li className="nav-line" />
            {/* <li>
              <Link
                className="menu_link"
                to="mailto:kaungmyatkyaw7012@gmail.com"
                target="_blank"
              >
                <AiOutlineMail className="nav-icon" />
                Contacto
              </Link>
            </li> */}
            <li className="nav-footer">
              <p>Privacy Policy</p>
              <p>Terms & Conditions</p>
              <p>© {new Date().getFullYear()} HG Rent A Car.</p>
              <p>All Rights Reserved.</p>
            </li>
            <li className="nav-info">
              <Link
                className="nav-info-link"
                target="_blank"
                to="https://www.facebook.com/profile.php?id=100071150734381"
              >
                <FaFacebookF className="nav-info-icon" />
              </Link>
              <Link
                className="nav-info-link"
                target="_blank"
                to="https://wa.me/+50376405950"
              >
                <FaWhatsapp size={20} className="nav-info-icon" />
              </Link>
            </li>
          </ul>
          {userData ? (
            <div className="user-info">
              <Link to="/user" className="user-profile">
                <div>
                  <p>{userData.name}</p>
                  <p className="user-email">{userData.email}</p>
                </div>
              </Link>
              <abbr title="Salir">
                <Link onClick={handleLogoutClick}>
                  <MdLogout className="signout-icon" />
                </Link>
              </abbr>
            </div>
          ) : (
            <Link className="user-info" to="/signin">
              Ingresar
              <MdLogin className="signout-icon" />
            </Link>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
