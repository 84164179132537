/* eslint-disable no-inner-declarations */
import { useState, useEffect } from 'react';

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined,
    height: undefined,
    isMobile: false
  });

  const handleResize = () => {
    // Set window width/height to state
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < 660
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Handler to call on window resize

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
}
