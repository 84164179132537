import React, { useEffect, useMemo } from 'react';
import Navbar from '../navbar';
import { Grid, Typography, Box, Container } from '@mui/material';
import '../../styles/main.css';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { sendFile } from '../../redux/authSlice';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function Dragzone(props) {
  const dispatch = useDispatch();
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx'
      ],
      'application/vnd.ms-excel': ['.xls'],
      'text/csv': ['.csv']
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        dispatch(sendFile(file));
      });
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) onDrop(acceptedFiles);
  }, [acceptedFiles]);

  const files = acceptedFiles?.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Box>
      <Navbar />
      <Container>
        <br />
        <br />
        <br />
        <Box textAlign="center" mt={4} mb={4}>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            fontWeight="bold"
          >
            Importador masivo a base de datos
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={6}>
            <div className="container">
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                fontWeight="bold"
              >
                Importar Vehiculos
              </Typography>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
                <em>(Only *.xls, *.csv o *.xlsx files will be accepted)</em>
                <aside>
                  <h4>Files</h4>
                  <ul>{files}</ul>
                </aside>
              </div>
            </div>
          </Grid> */}
          <Grid item xs={12} sm={6} md={6}>
            <div className="container">
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                fontWeight="bold"
              >
                Importar Clientes
              </Typography>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
                <em>(Only *.xls, *.csv o *.xlsx files will be accepted)</em>
                <aside>
                  <h4>Files</h4>
                  <ul>{files}</ul>
                </aside>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default Dragzone;
