import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import checkImage from '../../assets/check.png';
import { fetchReservationOrders } from '../../redux/reservationOrdersSlice';
import AppModal from '../Modal';
import ContractDocument from './ContractDocument';

const Contract = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reservationOrder.orders);
  const order = orders[0];
  const client = order?.clientId;
  const seller = order?.sellerId;
  const car = order?.vehicleId;
  const driver = order?.driverId;
  const typeCar = order?.vehicleId?.typeVehicle;

  const startDate = new Date(parseInt(order?.startDate));
  const endDate = new Date(parseInt(order?.endDate));

  useEffect(() => {
    dispatch(fetchReservationOrders({ orderId: id }));
  }, [dispatch, id]);

  if (isMobile) {
    return (
      <AppModal showCloseBtn={false}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img
            src={checkImage}
            alt="check"
            style={{ width: '4rem', height: '4rem', marginBottom: '2rem' }}
          />
          <div style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
            El contrato se ha generado con éxito
          </div>
          <PDFDownloadLink
            document={
              <ContractDocument
                order= {order}
                car={car}
                client={client}
                seller={seller}
                driver={driver}
                typeCar={typeCar}
                startDate={startDate}
                endDate={endDate}
              />
            }
            fileName="contract"
          >
            {({ loading }) =>
              loading ? (
                'Loading...'
              ) : (
                <div
                  style={{
                    padding: '20px 30px',
                    border: 'none',
                    fontSize: '1rem',
                    fontWeight: '600',
                    textTransform: 'uppercase',
                    color: '#fff',
                    borderRadius: '10px',
                    backgroundColor: ' var(--primary-color)'
                  }}
                >
                  Descargar
                </div>
              )
            }
          </PDFDownloadLink>
        </div>
      </AppModal>
    );
  }

  return (
    <PDFViewer width="100%" height="1000vh">
      <ContractDocument
        order= {order}  
        car={car}
        client={client}
        seller={seller}
        driver={driver}
        typeCar={typeCar}
        startDate={startDate}
        endDate={endDate}
      />
    </PDFViewer>
  );
};

export default Contract;
