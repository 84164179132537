import { Paper,styled  } from '@mui/material';

const DashboardItems = styled(Paper)(({ theme, type }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#fffafe' : '#fff',
  borderRadius: '8px',
  ...theme.typography.h3,
  fontWeight: 'bolder',
  padding: theme.spacing(3),
  textAlign: 'left',
  color: theme.palette.text.primary,
  ...(type === 'item2' && {
    backgroundColor: theme.palette.mode === 'light' ? '#fdfde1' : '#fff',
  }),
  ...(type === 'item3' && {
    backgroundColor: theme.palette.mode === 'light' ? '#eef6ff' : '#fff',
  }),
  ...(type === 'item4' && {
    backgroundColor: theme.palette.mode === 'light' ? '#feeeef' : '#fff',
  }),
}));


export default DashboardItems;
