import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import Icon from '../Icons';

function Modal({ children, onClose, showCloseBtn = true, width }) {
  const handleModalClick = (e) => {
    e.stopPropagation();
    return false;
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="modalWrapper" onClick={onClose}>
      <div className="modalBodyWrapper">
        <div className="modalBody" onClick={handleModalClick}>
          {showCloseBtn && (
            <div className="modalHeader">
              <button className="iconButton" onClick={onClose}>
                <Icon name="exit" />
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

export default function AppModal({ children, onClose, showCloseBtn, width }) {
  const [pageRendered, setPageRendered] = useState(false);

  useEffect(() => {
    setPageRendered(true);
  }, []);

  if (!pageRendered) return <></>;

  return ReactDOM.createPortal(
    <Modal onClose={onClose} showCloseBtn={showCloseBtn} width={width}>
      {children}
    </Modal>,
    document && document.getElementById('modal-root')
  );
}
