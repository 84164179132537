import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import Clients from './components/Clients/Clients';
import Contract from './components/Contract/Contract';
import DeleteCar from './components/DeleteCar';
import Detail from './components/Detail';
import EditCar from './components/EditCar/EditCar';
import Main from './components/Main/Main';
import { ProtectedRoute } from './components/ProtectedRoute';
import Reservations from './components/Reservations/Reservations';
import ReservedDetail from './components/ReservedDetail/ReservedDetail';
import ReservedEdit from './components/ReservedEdit';
import Sellers from './components/Sellers/Sellers';
import SignIn from './components/SignIn';
import User from './components/User';
import AddCar from './components/addCar';
import Dashboard from './components/Dashboard/Dashboard';
import Dragzone from './components/Dragzone';

const App = () => (
  <Router>
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cars/:id"
        element={
          <ProtectedRoute>
            <Detail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cars/:id/edit"
        element={
          <ProtectedRoute>
            <EditCar />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reserved"
        element={
          <ProtectedRoute>
            <Reservations />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reserved/:id"
        element={
          <ProtectedRoute>
            <ReservedDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reserved/:id/edit"
        element={
          <ProtectedRoute>
            <ReservedEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reserved/:id/contract"
        element={
          <ProtectedRoute>
            <Contract />
          </ProtectedRoute>
        }
      />
      <Route
        path="/add_car"
        element={
          <ProtectedRoute>
            <AddCar />
          </ProtectedRoute>
        }
      />
      <Route
        path="/delete_car"
        element={
          <ProtectedRoute>
            <DeleteCar />
          </ProtectedRoute>
        }
      />

      <Route
        path="/user"
        element={
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        }
      />
      <Route
        path="/usuarios"
        element={
          <ProtectedRoute>
            <Sellers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <ProtectedRoute>
            <Clients />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dragzone"
        element={
          <ProtectedRoute>
            <Dragzone />
          </ProtectedRoute>
        }
      />
    </Routes>
  </Router>
);

export default App;
