import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { queryToGraph } from '../helper/graphRequest';
import { getVehicleGraph } from '../graphql/Vehicle';
import { getOrdersGraph } from '../graphql/Order';
import _ from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';

// const baseURL = 'https://carrental-9ijm.onrender.com/users';
const baseURL = 'https://rent-car-backend-ecxw.onrender.com/api';

const initialState = {
  status: 'idle',
  orders: [],
  isLoading: false,
  isModalOpen: false,
  error: ''
};

export const fetchReservationOrders = createAsyncThunk(
  'reservationOrder/fetchReservationOrders',
  async (params) => {
    const response = await queryToGraph(getOrdersGraph(params)); //fetch(`${baseURL}/cars`);
    if (_.get(response, 'Order.isSuccess', false)) {
      return _.get(response, 'Order.result', []);
    }
    return _.get(response, 'Order.message', '');
  }
);

export const addReservationOrder = createAsyncThunk(
  'reservationOrder/addResservedCar',
  async ({
    sellerId,
    clientId,
    vehicleId,
    startDate,
    endDate,
    name,
    email,
    number,
    rolId,
    license,
    passport,
    idDocument,
    residenceAddress,
    address,
    amount,
    amountDescription,
    deposit
  }) => {
    const response = await axios.post(`${baseURL}/order`, {
      sellerId,
      clientId,
      vehicleId,
      startDate,
      endDate,
      name,
      email,
      number,
      rolId,
      license,
      passport,
      idDocument,
      residenceAddress,
      address,
      amount,
      amountDescription,
      deposit
    });
    return response.data;
  }
);

export const updateReservationOrder = createAsyncThunk(
  'reservationOrder/updatedResservedCar',
  async ({
    orderId,
    sellerId,
    clientId,
    vehicleId,
    startDate,
    endDate,
    deposit,
    file
  }) => {
    const formData = new FormData();
    formData.append('sellerId', sellerId);
    formData.append('clientId', clientId);
    formData.append('vehicleId', vehicleId);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('deposit', deposit);
    formData.append('file', file);

    const response = await axios.patch(
      `${baseURL}/order/${orderId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  }
);

export const deleteReservationOrder = createAsyncThunk(
  'reservationOrder/deleteReservedCar',
  async (orderId) => {
    const response = await axios.delete(`${baseURL}/order/${orderId}`);
    return response;
  }
);

const reservationOrderSlice = createSlice({
  name: 'reservationOrder',
  initialState,
  reducers: {
    // Add a reducer to reset status after a certain time
    resetStatus: (state) => ({
      ...state,
      status: 'idle'
    }),
    openModal: (state) => ({
      ...state,
      isModalOpen: true
    }),
    closeModal: (state) => ({
      ...state,
      isModalOpen: false
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservationOrders.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(fetchReservationOrders.fulfilled, (state, action) => ({
        ...state,
        status: 'success',
        isLoading: false,
        orders: action.payload
      }))
      .addCase(fetchReservationOrders.rejected, (state) => ({
        ...state,
        status: 'error',
        isLoading: false
      }))
      .addCase(addReservationOrder.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'adding'
      }))
      .addCase(addReservationOrder.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          toast.success('Reserva agendada con éxito!');
          return {
            ...state,
            status: 'added successfully',
            isLoading: false,
            isModalOpen: false,
            orders: [action.payload.result, ...state.orders]
          };
        }

        return {
          ...state,
          status: 'error',
          isLoading: false,
          isModalOpen: false,
          error: action.payload.errors
        };
      })
      .addCase(addReservationOrder.rejected, (state) => ({
        ...state,
        status: 'error',
        isModalOpen: false,
        isLoading: false
      }))
      .addCase(updateReservationOrder.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'updating'
      }))
      .addCase(updateReservationOrder.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          // Check if there are no errors
          toast.success('Reserva actualizada con éxito!');
          const newOrders = state.orders.map((order) => {
            if (order._id === action.payload.result._id) {
              return action.payload.result;
            }
            return order;
          });
          return {
            ...state,
            status: 'updated successfully',
            isLoading: false,
            isModalOpen: false,
            orders: newOrders,
            error: '' // Clear any previous errors
          };
        }

        toast.error(`Error: ${action.payload.message}`);
        return {
          ...state,
          status: 'error-updating',
          isLoading: false,
          error: action.payload.message
        };
      })
      .addCase(updateReservationOrder.rejected, (state) => ({
        ...state,
        status: 'error-deleting',
        isLoading: false
        // error: action.payload.errors,
      }))
      .addCase(deleteReservationOrder.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(deleteReservationOrder.fulfilled, (state, action) => {
        toast.success('Reserva eliminada éxitosamente');
        return {
          ...state,
          status: 'delete successfully',
          isLoading: false,
          orders: state.orders.filter(
            (order) => order?._id !== action?.payload?.data?.result?._id
          )
        };
      })
      .addCase(deleteReservationOrder.rejected, (state) => ({
        ...state,
        status: 'error',
        isLoading: false
      }));
  }
});

export const { resetStatus, openModal, closeModal } =
  reservationOrderSlice.actions;
export default reservationOrderSlice.reducer;
