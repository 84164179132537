import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  modeView: 'gallery'
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    updateView: (state, action) => {
      state.modeView = action.payload;
      if (action.payload === 'gallery') {
        toast.success('¡Se cambió a vista galería!');
      } else {
        toast.success('¡Se cambió a vista tabla!');
      }
    },
    resetStatus: (state) => {
      state.status = 'idle';
    },
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    }
  }
});

export const { updateView, resetStatus, openModal, closeModal } =
  optionsSlice.actions;
export default optionsSlice.reducer;
