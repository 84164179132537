const Year = ({ onChange, value, customClassName }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1968 },
    (_, index) => 1970 + index
  );

  return (
    <select
      className={customClassName || 'basic'}
      id="year"
      name="year"
      onChange={onChange}
      value={value}
    >
      <option value="" disabled>
        Año
      </option>
      {years
        ?.sort((a, b) => b - a)
        ?.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
    </select>
  );
};

export default Year;
