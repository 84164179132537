import React, { useEffect, useMemo, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomProvider, DateRangePicker } from 'rsuite';
import es_ES from 'rsuite/locales/es_ES';
import { roles } from '../constants/system';
import { fetchCars } from '../redux/carsSlice';
import {
  fetchReservationOrders,
  updateReservationOrder
} from '../redux/reservationOrdersSlice';
import { fetchUsers } from '../redux/usersSlice';
import '../styles/detail.css';
import Alert from './Alert';
import Loader from './Loader';
import { Autocomplete, TextField } from '@mui/material';

const ReservedEdit = () => {
  const { id } = useParams();
  const status = useSelector((state) => state.reservationOrder.status);
  const isLoading = useSelector((state) => state.reservationOrder.isLoading);
  const orders = useSelector((state) => state.reservationOrder.orders);
  const users = useSelector((state) => state.user.users);
  const sellersOptions = users
    ?.map((user) => {
      return (
        (user.rolId?._id === roles.seller ||
          user.rolId?._id === roles.admin) && {
          label: user.name,
          id: user._id
        }
      );
    })
    ?.filter(Boolean);
  const clientsOptions = users
    ?.map(
      (user) =>
        user.rolId?._id === roles.client && { label: user.name, id: user._id }
    )
    .filter(Boolean);
  const cars = useSelector((state) => state.car.cars);
  const carsOptions = cars?.map((car) => ({ label: car.idCar, id: car._id }));
  const order = orders?.find((order) => order._id === id);
  const defaultCar = order?.vehicleId;
  const seller = order?.sellerId;
  const client = order?.clientId;
  const startDate = useMemo(
    () => new Date(parseInt(order?.startDate)),
    [order?.startDate]
  );
  const endDate = useMemo(
    () => new Date(parseInt(order?.endDate)),
    [order?.endDate]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [sellersOptionsLoaded, setSellersOptionsLoaded] = useState(false);
  const [clientsOptionsLoaded, setClientsOptionsLoaded] = useState(false);
  const [vehicleLoaded, setVehicleLoaded] = useState(false);

  const [car, setCar] = useState(defaultCar);
  const [contractName, setContractName] = useState('');
  const [formData, setFormData] = useState({
    sellerId: '',
    clientId: '',
    vehicleId: '',
    startDate: '',
    endDate: '',
    file: null,
    datePickerValue: [new Date(), new Date()]
  });

  useEffect(() => {
    setFormData({
      sellerId: seller?._id,
      clientId: client?._id,
      vehicleId: car?._id,
      startDate,
      endDate,
      datePickerValue: [new Date(startDate), new Date(endDate)]
    });
    // console.log('########################################################');
    // console.log('🚀 ~  id:', id);
    // console.log('🚀 ~  endDate:', endDate);
    // console.log('🚀 ~  startDate:', startDate);
    // console.log('🚀 ~  car:', car);
    // console.log('🚀 ~  client:', client);
    // console.log('🚀 ~  seller:', seller);
  }, [seller, client, car, startDate, endDate, id]);

  useEffect(() => {
    if (formData.vehicleId) {
      const carSelected = cars?.find((car) => car?._id === formData.vehicleId);
      setCar(carSelected);
    }
  }, [formData.vehicleId, cars]);

  useEffect(() => {
    dispatch(fetchCars());
    dispatch(fetchUsers());
    dispatch(fetchReservationOrders());
  }, [dispatch]);

  useEffect(() => {
    if (sellersOptions) {
      setSellersOptionsLoaded(true);
    }
    if (clientsOptions) {
      setClientsOptionsLoaded(true);
    }
    if (carsOptions) {
      setVehicleLoaded(true);
    }
  }, [sellersOptions, clientsOptions, carsOptions]);

  const handleChangeSeller = (event, seller) => {
    setFormData({ ...formData, sellerId: seller?.id });
  };

  const handleChangeClient = (event, client) => {
    setFormData({ ...formData, clientId: client?.id });
  };

  const handleChangeCar = (event, car) => {
    setFormData({ ...formData, vehicleId: car?.id });
  };

  const handleChangeContract = (event) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setFormData((prevData) => ({
        ...prevData,
        file
      }));
      setContractName(file?.name);
    }
  };

  const handleChangeDate = (date) => {
    const startTimestamp = new Date(date[0]).getTime();
    const endTimestamp = new Date(date[1]).getTime();
    setFormData({
      ...formData,
      startDate: startTimestamp,
      endDate: endTimestamp,
      datePickerValue: [date[0], date[1]]
    });
  };

  const shouldDisableDate = (date) => {
    // const currentDate = new Date(date);
    // const startDate = new Date(parseInt(orders[0]?.startDate));
    // const endDate = new Date(parseInt(orders[0]?.endDate));

    const currentDate = new Date(date);
    const startDate = new Date(parseInt(orders[0]?.startDate));
    const endDate = new Date(parseInt(orders[0]?.endDate));

    return currentDate >= startDate && currentDate <= endDate;
  };

  const handleUpdateOrder = () => {
    const { sellerId, clientId, vehicleId, startDate, endDate, file } =
      formData;
    dispatch(
      updateReservationOrder({
        orderId: id,
        sellerId,
        clientId,
        vehicleId,
        startDate,
        endDate,
        file
      })
    );
    navigate(`/reserved/${id}`, { replace: true });
  };

  if (!order) {
    return <h1 className="heading">Loading Detail...Please Wait</h1>;
  }

  const handleDeleteCar = () => {};

  return (
    <>
      <section id="detail">
        <header className="header">
          <IoIosArrowBack className="back-btn" onClick={() => navigate(-1)} />
          <h2 className="title" style={{ color: 'white' }}>
            {car?.brand}
          </h2>
        </header>

        <div className="detail-container">
          <div className="banner">
            <div className="img-container">
              <img src={car?.imgCar} alt={car?.brand} className="img" />
            </div>
          </div>

          <div className="detail-wrapper">
            <div className="details">
              <div className="features-table">
                <div className="feature-cell bold">
                  <span>Vendedor</span>
                </div>
                <div className="feature-cell">
                  {sellersOptionsLoaded && (
                    <Autocomplete
                      value={sellersOptions?.find(
                        (sellerSelected) =>
                          sellerSelected?.id === formData?.sellerId
                      )}
                      style={{ width: '75%' }}
                      disablePortal
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      id="combo-box-demo"
                      options={sellersOptions}
                      onChange={handleChangeSeller}
                      renderInput={(params) => (
                        <TextField {...params} label="Vendedores" />
                      )}
                    />
                  )}
                </div>
                <div className="feature-cell bold">
                  <span>Cliente</span>
                </div>
                <div className="feature-cell">
                  {clientsOptionsLoaded && (
                    <Autocomplete
                      value={clientsOptions?.find(
                        (clientSelected) =>
                          formData?.clientId === clientSelected?.id
                      )}
                      style={{ width: '75%' }}
                      disablePortal
                      id="combo-box-demo"
                      options={clientsOptions}
                      onChange={handleChangeClient}
                      renderInput={(params) => (
                        <TextField {...params} label="Clientes" />
                      )}
                    />
                  )}
                </div>
                <div className="feature-cell bold">
                  <span>Placa del carro</span>
                </div>
                <div className="feature-cell">
                  {vehicleLoaded && (
                    <Autocomplete
                      value={carsOptions?.find(
                        (carSelected) => formData?.vehicleId === carSelected?.id
                      )}
                      style={{ width: '75%' }}
                      disablePortal
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      id="combo-box-demo"
                      options={carsOptions}
                      onChange={handleChangeCar}
                      renderInput={(params) => (
                        <TextField {...params} label="Carros" />
                      )}
                    />
                  )}
                </div>
                <div className="feature-cell bold">
                  <span>Marca</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.brand}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Fecha</span>
                </div>
                <div className="feature-cell">
                  <CustomProvider locale={es_ES}>
                    <DateRangePicker
                      size="lg"
                      block
                      showOneCalendar
                      locate={'es_ES'}
                      placeholder="Selecciona fechas de reserva"
                      shouldDisableDate={shouldDisableDate}
                      onChange={handleChangeDate}
                      value={formData.datePickerValue}
                    />
                  </CustomProvider>
                </div>
                <div className="feature-cell bold">
                  <span>Contrato</span>
                </div>
                <div className="feature-cell">
                  <label htmlFor="file" className="uploadLabel">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept="application/pdf"
                      onChange={handleChangeContract}
                      className="fileInput"
                    />
                    <span className="uploadText">
                      {order?.pdfReference
                        ? `${order?.pdfReference?.slice(0, 15)}...`
                        : contractName
                          ? `${contractName?.slice(0, 20)}...`
                          : 'Subir Archivo'}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="reserve-container">
              {isLoading && status === 'updating' ? (
                <Loader stroke="#fff" />
              ) : (
                <button
                  type="button"
                  className="reserve-btn"
                  onClick={handleUpdateOrder}
                >
                  Guardar
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      {Boolean(isAlertVisible) && (
        <Alert
          title="Estás seguro que quieres eliminar este auto?"
          description="Esta acción es irreversible"
          type="delete"
          onCloseAlert={() => setIsAlertVisible(false)}
          primaryAction={handleDeleteCar}
          isLoading={isLoading && status === 'deleting'}
        />
      )}
    </>
  );
};

export default ReservedEdit;
