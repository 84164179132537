import * as React from 'react';

const EmptyImg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="680.61745"
    height="565"
    viewBox="0 0 680.61745 565"
    style={{
      maxWidth: '100%', // Ajusta el ancho máximo del SVG al 100% del contenedor
      height: 'auto', // Establece la altura automática para mantener la proporción
      display: 'block', // Asegura que el SVG se comporte como un bloque
      margin: '0 auto' // Centra el SVG horizontalmente
    }}
  >
    <path
      d="M846.44128,200.5v76h-2v-.81h-669v.81h-2v-76a33.03244,33.03244,0,0,1,33-33h607A33.03244,33.03244,0,0,1,846.44128,200.5Z"
      transform="translate(-173.44128 -167.5)"
      fill="#dc143c"
    />
    <path
      d="M813.44128,167.5h-607a33.03244,33.03244,0,0,0-33,33v434a33.03244,33.03244,0,0,0,33,33h607a33.03244,33.03244,0,0,0,33-33v-434A33.03244,33.03244,0,0,0,813.44128,167.5Zm31,467a31.03964,31.03964,0,0,1-31,31h-607a31.03963,31.03963,0,0,1-31-31v-434a31.03963,31.03963,0,0,1,31-31h607a31.03964,31.03964,0,0,1,31,31Z"
      transform="translate(-173.44128 -167.5)"
      fill="#3f3d56"
    />
    <circle cx="136" cy="54.5" r="20" fill="#fff" />
    <circle cx="537" cy="54.5" r="20" fill="#fff" />
    <path
      d="M367.22591,445.00191H248.24968a19.033,19.033,0,0,1-19.01145-19.01144V369.75784a19.033,19.033,0,0,1,19.01145-19.01144H367.22591a19.033,19.033,0,0,1,19.01145,19.01144v56.23263A19.033,19.033,0,0,1,367.22591,445.00191Z"
      transform="translate(-173.44128 -167.5)"
      fill="#dc143c"
    />
    <path
      d="M569.62448,445.00191H450.64824a19.033,19.033,0,0,1-19.01144-19.01144V369.75784a19.033,19.033,0,0,1,19.01144-19.01144H569.62448a19.033,19.033,0,0,1,19.01144,19.01144v56.23263A19.033,19.033,0,0,1,569.62448,445.00191Z"
      transform="translate(-173.44128 -167.5)"
      fill="#dc143c"
    />
    <path
      d="M569.62448,590.29329H450.64824a19.60963,19.60963,0,0,1-19.58755-19.58755V514.47255A19.60962,19.60962,0,0,1,450.64824,494.885H569.62448A19.60962,19.60962,0,0,1,589.212,514.47255v56.23319A19.60962,19.60962,0,0,1,569.62448,590.29329Z"
      transform="translate(-173.44128 -167.5)"
      fill="#f2f2f2"
    />
    <path
      d="M366.83575,590.29329H247.85951A19.60963,19.60963,0,0,1,228.272,570.70574V514.47255A19.60962,19.60962,0,0,1,247.85951,494.885H366.83575a19.60962,19.60962,0,0,1,19.58755,19.58754v56.23319A19.60963,19.60963,0,0,1,366.83575,590.29329Z"
      transform="translate(-173.44128 -167.5)"
      fill="#f2f2f2"
    />
    <path
      d="M772.023,590.29329H653.0468a19.60962,19.60962,0,0,1-19.58754-19.58755V514.47255A19.60962,19.60962,0,0,1,653.0468,494.885H772.023a19.60962,19.60962,0,0,1,19.58755,19.58754v56.23319A19.60963,19.60963,0,0,1,772.023,590.29329Z"
      transform="translate(-173.44128 -167.5)"
      fill="#f2f2f2"
    />
    <path
      d="M772.023,445.115H653.0468a19.60962,19.60962,0,0,1-19.58754-19.58754V369.29426a19.60962,19.60962,0,0,1,19.58754-19.58755H772.023a19.60963,19.60963,0,0,1,19.58755,19.58755v56.23319A19.60962,19.60962,0,0,1,772.023,445.115Z"
      transform="translate(-173.44128 -167.5)"
      fill="#f2f2f2"
    />
    <circle cx="134.29652" cy="230.37416" r="32.26184" fill="#fff" />
    <path
      d="M304.5399,410.1813a3.34586,3.34586,0,0,1-2.01323-.66875l-.036-.027-7.58175-5.80486a3.3684,3.3684,0,1,1,4.09732-5.34756l4.91089,3.7656,11.6052-15.13487a3.36827,3.36827,0,0,1,4.72263-.62342l-.07215.098.074-.09655a3.3722,3.3722,0,0,1,.62341,4.72267l-13.6505,17.80224A3.36974,3.36974,0,0,1,304.5399,410.1813Z"
      transform="translate(-173.44128 -167.5)"
      fill="#dc143c"
    />
    <circle cx="337.27119" cy="229.91085" r="32.26184" fill="#fff" />
    <path
      d="M507.51457,409.718a3.3459,3.3459,0,0,1-2.01324-.66874l-.036-.027-7.58176-5.80486a3.3684,3.3684,0,1,1,4.09733-5.34756l4.91088,3.7656,11.6052-15.13488a3.3683,3.3683,0,0,1,4.72264-.62341l-.07215.098.074-.09654a3.37217,3.37217,0,0,1,.62341,4.72266l-13.65049,17.80224A3.36974,3.36974,0,0,1,507.51457,409.718Z"
      transform="translate(-173.44128 -167.5)"
      fill="#dc143c"
    />
    <path
      d="M728.75351,549.64979a10.74269,10.74269,0,0,0-2.18282-16.32737l-18.01117-96.17273-21.90458,8.12263,24.59219,91.98054a10.80091,10.80091,0,0,0,17.50638,12.39693Z"
      transform="translate(-173.44128 -167.5)"
      fill="#a0616a"
    />
    <path
      d="M553.96858,402.90038a11.40614,11.40614,0,0,1,.02187,1.79608l42.59,32.77955,12.0777-4.95957,9.86722,17.24911-20.528,10.85781a8.66955,8.66955,0,0,1-10.27652-1.62745l-42.571-43.8892a11.37563,11.37563,0,1,1,8.81868-12.20633Z"
      transform="translate(-173.44128 -167.5)"
      fill="#a0616a"
    />
    <polygon
      points="480.028 552.849 467.769 552.849 461.936 515.561 480.031 515.562 480.028 552.849"
      fill="#a0616a"
    />
    <path
      d="M656.59617,732.23337l-39.53051-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
      transform="translate(-173.44128 -167.5)"
      fill="#2f2e41"
    />
    <polygon
      points="526.028 552.849 513.769 552.849 507.936 515.561 526.031 515.562 526.028 552.849"
      fill="#a0616a"
    />
    <path
      d="M702.59617,732.23337l-39.53051-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
      transform="translate(-173.44128 -167.5)"
      fill="#2f2e41"
    />
    <path
      d="M638.6643,712.9793a4.49017,4.49017,0,0,1-4.47485-4.07226L623.50586,565.21758l.50488-.041,73.521-6.043.01978.52246L703.396,708.50664a4.49964,4.49964,0,0,1-4.49707,4.668H684.48339a4.475,4.475,0,0,1-4.44751-3.81543L660.45971,589.11407a.5.5,0,0,0-.99414.07226L658.57763,707.91a4.50454,4.50454,0,0,1-4.26318,4.46192l-15.40869.60058C638.82495,712.97735,638.74414,712.9793,638.6643,712.9793Z"
      transform="translate(-173.44128 -167.5)"
      fill="#2f2e41"
    />
    <circle cx="477.7719" cy="191.56438" r="24.56103" fill="#a0616a" />
    <path
      d="M655.96792,574.38164a121.03726,121.03726,0,0,1-31.76839-4.34179,4.51046,4.51046,0,0,1-3.2358-4.68653c3.30645-49.69336,4.08385-88.25683-2.86906-114.939-2.96342-11.37207-1.618-23.34912,3.69108-32.85986,7.98992-14.313,22.67547-23.02442,38.34016-22.72364h0q1.12287.02124,2.26805.08008c23.7729,1.22412,42.29735,22.73047,41.29428,47.9419l-4.78177,120.16845a4.43993,4.43993,0,0,1-2.81538,4.04395A114.24513,114.24513,0,0,1,655.96792,574.38164Z"
      transform="translate(-173.44128 -167.5)"
      fill="#3f3d56"
    />
    <path
      d="M622.13643,459.64084l-18.39762-22.54449a5.76149,5.76149,0,0,1,1.51427-8.59185l24.92148-14.85005a16.00071,16.00071,0,0,1,20.16015,24.85261l-19.47729,21.37306a5.7615,5.7615,0,0,1-8.721-.23928Z"
      transform="translate(-173.44128 -167.5)"
      fill="#3f3d56"
    />
    <path
      d="M680.01136,456.81194a5.7553,5.7553,0,0,1-3.16409-3.60611l-8.282-27.70526A16.00071,16.00071,0,0,1,697.50865,411.849L713.525,436.03743a5.7615,5.7615,0,0,1-2.36144,8.3986l-26.35426,12.3361A5.755,5.755,0,0,1,680.01136,456.81194Z"
      transform="translate(-173.44128 -167.5)"
      fill="#3f3d56"
    />
    <path
      d="M663.35014,379.21724l-18.206-4.16705c-1.87794-.42983-4.13413-1.24991-4.39457-3.15873-.35-2.56513,3.34149-4.35213,3.001-6.91854-.32983-2.48578-3.69254-2.80615-6.09035-3.53991a9.11065,9.11065,0,0,1-5.67236-11.34023c-2.59437,3.656-8.52155,3.96913-11.88655,1.00712s-4.01043-8.33613-1.98963-12.33777a14.28744,14.28744,0,0,1,10.724-7.2405,22.61663,22.61663,0,0,1,13.02306,2.42787c.26664-2.83339,3.80421-3.98207,6.65-4.00735a28.43074,28.43074,0,0,1,26.64315,19.4463c3.492,11.25212,1.15619,23.58635-8.6142,30.16993Z"
      transform="translate(-173.44128 -167.5)"
      fill="#2f2e41"
    />
    <path
      d="M846.72868,732.5h-381a1,1,0,1,1,0-2h381a1,1,0,1,1,0,2Z"
      transform="translate(-173.44128 -167.5)"
      fill="#3f3d56"
    />
  </svg>
);

export default EmptyImg;
