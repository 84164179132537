import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { ViewModule, ViewList } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateView } from '../../redux/options';

const GalleryTableToggle = () => {
  const dispatch = useDispatch();
  const optionsSettings = useSelector((state) => state.options);
  const handleChange = (event, newView) => {
    if (newView !== null) {
      dispatch(updateView(newView));
    }
  };

  return (
    <ToggleButtonGroup
      value={optionsSettings?.modeView}
      exclusive
      onChange={handleChange}
      aria-label="view mode"
      style={{ backgroundColor: '#dc143c', color: 'white' }} // Cambiar el color de fondo y texto
    >
      <Tooltip title="Vista de Galería" placement="top">
        <ToggleButton value="gallery" aria-label="gallery view">
          <ViewModule style={{ color: 'white' }} />{' '}
          {/* Cambiar el color del icono */}
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Vista de Tabla" placement="top">
        <ToggleButton value="table" aria-label="table view">
          <ViewList style={{ color: 'white' }} />{' '}
          {/* Cambiar el color del icono */}
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

export default GalleryTableToggle;
