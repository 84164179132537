import axios from 'axios';

export const queryToGraph = async (graphqlQuery) => {
  try {
    const response = await axios({
      url: 'https://rent-car-backend-ecxw.onrender.com/api' + '/graphql',
      method: 'post',
      data: graphqlQuery
    });

    if (response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    console.error(
      '🚀 ~ file: graphRequest.js ~ line 17 ~ queryToGraph ~ error',
      error
    );
  }
};
