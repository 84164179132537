import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCars } from '../../../redux/carsSlice';


export const useFilters = () => {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    brand: null,
    model: null,
    color: null,
    fuelId: null,
    year: null,
    isFiltered: false
  });


  const handleChangeFilter = (event) => {
    const { name, value } = event.target;
    setFilters((prevData) => ({
      ...prevData,
      [name]: name === 'year' ? Number(value) : value
    }));
  };


  const clearFilters = () => {
    setFilters({
      brand: null,
      model: null,
      color: null,
      fuelId: null,
      year: null,
      isFiltered: false
    });
    dispatch(fetchCars());
  };


  const handleSearch = (e) => {
    e.preventDefault();
    const { brand, model, color, fuelId, year } = filters;
    dispatch(
      fetchCars({
        brand,
        model,
        color,
        fuelId,
        year
      })
    );
    setFilters({ ...filters, isFiltered: true });
  };


  return {
    filters,
    isFiltered: filters.isFiltered,
    clearFilters,
    handleChangeFilter,
    handleSearch,
  };
};
