import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { queryToGraph } from "../helper/graphRequest";
import { getSummaryGraph } from "../graphql/Summary";
import _ from "lodash";

const baseURL = process?.env.REACT_APP_URL_API;

const initialState = {
  summary: [],
  startDate: null,
  endDate: null,
  status: 'idle',
  isLoading: false,
  error: ''
};


export const fetchSummaryGraph = createAsyncThunk('summary/fetchSummaryGraph', async (params) => {
        const response = await queryToGraph(getSummaryGraph(params));
        if(_.get(response, 'Summary.isSuccess', false)) {
            return _.get(response, 'Summary.result', []);
        }
        return _.get(response, 'Summary.message', '');
    });


const summarySlice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
      // Add a reducer to reset status after a certain time
      resetStatus: (state) => ({
        ...state,
        status: 'idle'
      }),
      openModal: (state) => ({
        ...state,
        isModalOpen: true
      }),
      closeModal: (state) => ({
        ...state,
        isModalOpen: false
      })
    },

    extraReducers: (builder) => {
        
        builder
        .addCase(fetchSummaryGraph.pending, (state) => ({
            ...state,
            status: 'fetching summary graph',
            isLoading: true
        }))
        .addCase(fetchSummaryGraph.fulfilled, (state, action) => ({
            ...state,
            status: 'success',
            isLoading: false,
            summaryGraphData: action.payload
        }))
        .addCase(fetchSummaryGraph.rejected, (state, action) => ({
            ...state,
            status: 'error',
            isLoading: false,
            error: action.error.message
        })); 
    }
});

export const { resetStatus, openModal, closeModal } = summarySlice.actions;
export default summarySlice.reducer;
