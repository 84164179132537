import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { FiUser, FiLock } from 'react-icons/fi';
import { loginUser } from '../redux/authSlice';
import logo from '../assets/logo.png';
import '../styles/form.css';
import { toast } from 'react-toastify';

const SignInComponent = () => {
  const [email, setEmail] = useState('');
  const [passsword, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);

  const handleSignIn = async () => {
    if (!email || !passsword) {
      toast.error('Ingrese las credenciales');
    } else {
      dispatch(loginUser({ email, passsword }));
    }
  };

  useEffect(() => {
    if (userData.status === 'loading') {
      setLoading(true);
    } else if (userData.status === 'logged in successfully') {
      navigate('/');
      localStorage.setItem('user', JSON.stringify(userData));
    }
  }, [userData, navigate]);

  return (
    <div className="form">
      {/*  {userData.status === 'error' ? (
        <p className="error">{userData.error}</p>
      ) : null} */}
      <h1 className="form-title">
        Bienvenido <br />
        Ingrese sus credenciales para continuar
      </h1>
      <div className="logo-container">
        <img src={logo} alt="logo" className="logo-img" />
      </div>
      <div className="inputs">
        <div className="input-wrapper">
          <FiUser className="icon" />
          <input
            className="email-input"
            type="email"
            placeholder="Correo Electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <FiLock className="icon" />
          <input
            className="password-input"
            type="password"
            placeholder="Contraseña"
            value={passsword}
            onKeyDown={(e) => {
              const _key = e.keyCode || e.which;
              if (_key == 13) {
                handleSignIn();
              }
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <button
          className="btn primary-btn"
          type="button"
          onClick={handleSignIn}
        >
          {userData.status === 'loading' ? (
            <BeatLoader loading={loading} color="#fff" size={9} />
          ) : (
            'Iniciar sesión'
          )}
        </button>
      </div>
    </div>
  );
};

export default SignInComponent;
