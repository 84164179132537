import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';
import React from 'react';
import logo from '../../assets/logo.png';
import secondPage from '../../assets/contract_second_page.jpg';
import tiktok from '../../assets/tiktok_logo.png';
import { Row } from 'rsuite';
import camioneta from '../../assets/Camioneta.PNG';
import microbus from '../../assets/Microbus.PNG';
import pickup from '../../assets/pickup.png';
import sedan from '../../assets/Sedan.PNG';
import suv from '../../assets/suv.png';

import NexaHeavy from '../../fonts/Nexa-Heavy.ttf';
//	http://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf

// Fonts
Font.register({
  family: 'Oswald',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf'
    },
    {
      src: 'https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf',
      fontWeight: 'bold'
    },
    {
      src: 'https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf',
      fontWeight: 'normal'
    }
  ]
});

Font.register({
  family: 'Nexa Heavy',
  src: NexaHeavy
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  section: {
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20
  },
  seccion: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 20,
    marginBottom: 20,
    height: 30,
    width: 800,
    backgroundColor: '#c4140c'
  },
  seccionGris: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 20,
    marginBottom: 20,
    height: 30,
    width: 10,
    backgroundColor: '#b7b1b0'
  },
  seccionCarImg: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    marginBottom: 20,
    height: 60,
    width: 800,
    backgroundColor: '#c4140c'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    backgroundColor: '#c4140c'
  },
  headerCenter: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 40
  },
  headerTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 6,
    fontFamily: 'Nexa Heavy',
    color: 'white'
  },
  title: {
    fontFamily: 'Nexa Heavy',
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 12
  },
  subtitle: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 12,
    fontFamily: 'Nexa Heavy',
    color: 'white'
  },
  multipleField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
    flexWrap: 'wrap'
  },
  field: {
    flexDirection: 'row',
    alignItems: 'center',
    //marginHorizontal: 2,
    //gap: 6,
    marginBottom: 4
  },
  fieldAlone: {
    flexDirection: 'row',
    gap: 6,
    marginBottom: 10
  },
  fieldLabel: {
    fontSize: 10,
    fontWeight: 'light',
    minWidth: 110,
    marginRight: 5
  },
  fieldLabelSeccion: {
    fontSize: 10,
    minWidth: 110,
    fontFamily: 'Nexa Heavy',
    color: 'white',
    paddingLeft: 20
  },
  boldLabel: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  miniFieldLabel: {
    fontSize: 10
  },
  backColorFieldTxt: {
    backgroundColor: '#d5eafa',
    width: 120
  },
  colorFieldMarca: {
    backgroundColor: '#d5eafa',
    width: 105
  },
  colorFieldModelo: {
    backgroundColor: '#d5eafa',
    width: 132
  },
  colorFieldPlaca: {
    backgroundColor: '#d5eafa',
    width: 120
  },
  colorFieldColor: {
    backgroundColor: '#d5eafa',
    width: 143
  },
  colorFieldYear: {
    backgroundColor: '#d5eafa',
    width: 120
  },
  backColorTransmi: {
    backgroundColor: '#d5eafa',
    width: 80
  },
  colorField: {
    backgroundColor: '#d5eafa',
    width: 70
  },
  colorFieldGas: {
    backgroundColor: '#d5eafa',
    width: 115
  },
  colorFieldInsurance: {
    backgroundColor: '#d5eafa',
    width: 90
  },
  colorFieldDeposito: {
    backgroundColor: '#d5eafa',
    width: 125
  },
  colorFieldPrice: {
    backgroundColor: '#d5eafa',
    width: 70
  },
  colorFieldAssitance: {
    backgroundColor: '#d5eafa',
    width: 70
  },
  colorFieldPoliza: {
    backgroundColor: '#d5eafa',
    width: 109
  },
  backColorName: {
    backgroundColor: '#d5eafa',
    width: 600
  },
  backColorAdress: {
    backgroundColor: '#d5eafa',
    width: 450
  },
  fieldTxt: {
    fontSize: 10,
    fontFamily: 'Nexa Heavy',
    fontWeight: 'bold',
    color: '#909294',
    marginLeft: 5
  },
  fieldTxtSeccion: {
    fontSize: 10,
    fontFamily: 'Nexa Heavy',
    color: 'white'
  },
  logo: {
    width: 100,
    height: 100
  },
  socialMediasContainer: {
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'center'
  },
  socialMedia: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4
  },
  socialMediaLogo: {
    width: 16,
    height: 16
  },
  boldSmallText: {
    fontSize: 8,
    fontWeight: 'bold'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between' /* Espacio entre columnas */,
    gap: 20 /* Espacio entre columnas opcional */
  },
  containerImg: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  column: {
    flex: 1 /* Las columnas ocuparán el mismo espacio */,
    padding: 0
  },
  margin: {
    marginBottom: 9
  },
  image: {
    alignContent: 'center',
    width: 400,
    height: 400 // Ajusta según sea necesario
  }
});

const textItems = [
  '1 - Mantener el VEHICULO en óptimas condiciones.',
  '2 - Responder civil y penalmente ante daños a terceros.',
  '3 - Pagar todos aquellos daños que la compañía aseguradora no asuma.',
  '4 - No permitir que otras personas manejen el vehículo.',
  '5 - No manejar sin Licencia.',
  '6 - No utilizar el vehículo para arrastrar algún remolque.',
  '7 - Pagar todos los daños ocasionados al vehículo como resultado de los efectos del alcoholismo o drogadicción de parte del cliente.',
  '8 - Apropiación indebida por parte del cliente.'
];

const textItemsEN = [
  '1 - Maintain the VEHICLE in optimal condigtgion.',
  '2 - Responde civilly and criminally for damages to third parties.',
  '3 - Pay all damages that the insurance company does not assume.',
  '4 - Do not allow other persons to operate the vehicle.',
  '5 - Do not use the vehicle without a License.',
  '6 - Do not use the vehicle for towing any trailer.',
  '7 - Pay all the damages caused to the vehicle as a result of the effects of alcoholism or drug addiction on the part of the customer.',
  '8 - Misappropriation by the customer.'
];

//solo para volver a subir

// Create Document Component
const ContractDocument = ({
  client,
  car,
  startDate,
  endDate,
  order,
  driver,
  typeCar
}) => {
  const tipoCarro = typeCar?.name;

  const imgVehicle = () => {
    switch (tipoCarro) {
      case 'Sedan':
        return sedan;
      case 'Camioneta':
        return camioneta;
      case 'Pick Up':
        return pickup;
      case 'Microbus':
        return microbus;
      case 'SUV':
        return suv;
      default:
        return null;
    }
  };

  const vehicleImage = imgVehicle();
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image source={logo} style={styles.logo} />
          <View style={styles.headerCenter}>
            <Text style={styles.headerTitle}>CONTRATO</Text>
            <Text style={styles.subtitle}>ALQUILER DE VEHICULOS</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.seccionGris}></View>
          <View style={styles.seccion}>
            <Text style={styles.fieldLabelSeccion}>Fecha de reservación:</Text>
            <Text style={styles.fieldLabelSeccion}>
              Salida:{' '}
              <Text style={styles.fieldTxtSeccion}>
                {`${startDate?.getDate()}/${
                  startDate?.getMonth() + 1
                }/${startDate?.getFullYear()}`}
              </Text>
            </Text>
            <Text style={styles.fieldLabelSeccion}>
              Entrada:{' '}
              <Text style={styles.fieldTxtSeccion}>
                {`${endDate?.getDate()}/${
                  endDate?.getMonth() + 1
                }/${endDate?.getFullYear()}`}
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.title}>Características del vehículo</Text>
          <View style={styles.multipleField}>
            <View style={styles.field}>
              <Text style={[styles.fieldLabel]}>Marca:</Text>
              <View style={[styles.colorFieldMarca, { marginLeft: -80 }]}>
                <Text style={styles.fieldTxt}>{car?.brand}</Text>
              </View>
            </View>
            <View style={[styles.field, { marginLeft: -15 }]}>
              <Text style={[styles.fieldLabel]}>Modelo:</Text>
              <View style={[styles.colorFieldModelo, { marginLeft: -75 }]}>
                <Text style={styles.fieldTxt}>{car?.model}</Text>
              </View>
            </View>
            <View style={[styles.field]}>
              <Text style={[styles.fieldLabel]}>Placa:</Text>
              <View style={[styles.colorFieldPlaca, { marginLeft: -83 }]}>
                <Text style={styles.fieldTxt}>{car?.idCar}</Text>
              </View>
            </View>
          </View>
          <View style={styles.multipleField}>
            <View style={styles.field}>
              <Text style={styles.fieldLabel}>
                {car?.is4x4 ? '4x4' : '4x2'}
              </Text>
            </View>
            <View style={[styles.field]}>
              <Text style={styles.fieldLabel}> Color:</Text>
              <View style={[styles.colorFieldColor, { marginLeft: -83 }]}>
                <Text style={styles.fieldTxt}>{car?.color}</Text>
              </View>
            </View>
            <View style={[styles.field]}>
              <Text style={styles.fieldLabel}>Año:</Text>
              <View style={[styles.colorFieldYear, { marginLeft: -90 }]}>
                <Text style={styles.fieldTxt}>{car?.year}</Text>
              </View>
            </View>
          </View>

          <View style={styles.multipleField}>
            <View style={styles.field}>
              <Text style={styles.fieldLabel}>Transmisión:</Text>
              <View style={[styles.backColorTransmi, { marginLeft: -55 }]}>
                <Text style={styles.fieldTxt}>{car?.transmissionId?.name}</Text>
              </View>
            </View>
            <View style={[styles.field, { marginLeft: -30 }]}>
              <Text style={styles.fieldLabel}> Combustible:</Text>
              <View style={[styles.colorFieldGas, { marginLeft: -55 }]}>
                <Text style={styles.fieldTxt}>{car?.fuelId.name}</Text>
              </View>
            </View>
            <View style={[styles.field, { marginLeft: -10 }]}>
              <Text style={styles.fieldLabel}>Aseguradora:</Text>
              <View style={[styles.colorFieldInsurance, { marginLeft: -55 }]}>
                <Text style={styles.fieldTxt}>{car?.insurance}</Text>
              </View>
            </View>
          </View>

          <View style={styles.multipleField}>
            <View style={styles.field}>
              <Text style={styles.fieldLabel}>Precio por día:</Text>
              <View style={[styles.colorFieldPrice, { marginLeft: -45 }]}>
                <Text style={styles.fieldTxt}>{`$${car?.price}`}</Text>
              </View>
            </View>
            <View style={styles.field}>
              <Text style={[styles.fieldLabel, { marginLeft: -23 }]}>
                Depósito:
              </Text>
              <View style={[styles.colorFieldDeposito, { marginLeft: -70 }]}>
                <Text style={styles.fieldTxt}>{`$${order?.deposit}`}</Text>
              </View>
            </View>
            <View style={styles.field}>
              <Text style={[styles.fieldLabel, { marginRight: 15 }]}>
                Deducible:
              </Text>
              <View style={[styles.backColorTransmi, { marginLeft: -65 }]}>
                <Text style={styles.fieldTxt}>{`$${car?.deductible}`}</Text>
              </View>
            </View>
          </View>

          <View style={styles.multipleField}>
            <View style={styles.field}>
              <Text style={styles.fieldLabel}>Póliza:</Text>
              <View style={[styles.colorFieldPoliza, { marginLeft: -83 }]}>
                <Text style={styles.fieldTxt}>{car?.nPolicy}</Text>
              </View>
            </View>
            <View style={styles.field}>
              <Text style={[styles.fieldLabel, { marginLeft: -50 }]}>
                Número de asistencia:
              </Text>
              <View style={[styles.colorFieldAssitance, { marginLeft: -15 }]}>
                <Text style={styles.fieldTxt}>{car?.nAttendance}</Text>
              </View>
            </View>
            <View style={styles.field}>
              <Text style={styles.fieldLabel}></Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.seccionGris}></View>
          <View style={styles.seccion}>
            <Text style={styles.fieldLabelSeccion}>
              INFORMACION DEL CLIENTE
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.fieldAlone}>
            <Text style={styles.fieldLabel}>Nombre:</Text>
            <View style={[styles.backColorName, { marginLeft: -80 }]}>
              <Text style={styles.fieldTxt}>{client?.name}</Text>
            </View>
          </View>

          {client?.residenceAddress && (
            <View style={styles.multipleField}>
              <View style={styles.field}>
                <Text style={styles.fieldLabel}>Dirección de residencia:</Text>
                <View style={[styles.backColorAdress]}>
                  <Text style={styles.fieldTxt}>
                    {client?.residenceAddress}
                  </Text>
                </View>
              </View>
            </View>
          )}
          <View style={styles.multipleField}>
            <View style={styles.field}>
              <Text style={styles.fieldLabel}>Licencia:</Text>
              <View style={[styles.backColorFieldTxt, { marginLeft: -70 }]}>
                <Text style={styles.fieldTxt}>{client?.license}</Text>
              </View>
            </View>
            {client?.passport && (
              <View style={styles.field}>
                <Text style={[styles.fieldLabel, { marginLeft: 50 }]}>
                  Pasaporte:
                </Text>
                <View style={[styles.backColorFieldTxt, { marginLeft: -65 }]}>
                  <Text style={styles.fieldTxt}>{client?.passport}</Text>
                </View>
              </View>
            )}
            <View style={styles.field}>
              <Text style={styles.fieldLabel}></Text>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.seccionGris}></View>
          <View style={styles.seccion}>
            <Text style={styles.fieldLabelSeccion}>CONDUCTOR DESIGANDO</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.field}>
            <Text style={styles.fieldLabel}>Nombre:</Text>
            <View style={[styles.backColorName, { marginLeft: -70 }]}>
              <Text style={styles.fieldTxt}>{driver?.name}</Text>
            </View>
          </View>
          <View style={styles.multipleField}>
            <View style={styles.field}>
              <Text style={styles.fieldLabel}>Licencia:</Text>
              <View style={[styles.backColorFieldTxt, { marginLeft: -70 }]}>
                <Text style={styles.fieldTxt}>{driver?.license}</Text>
              </View>
            </View>
            <View style={[styles.field]}>
              <Text style={styles.fieldLabel}>Documento de identidad:</Text>
              <View style={[styles.backColorFieldTxt]}>
                <Text style={styles.fieldTxt}>{driver?.idDocument}</Text>
              </View>
            </View>
          </View>
          <hr style={{ border: '1px solid #2596be', margin: '5px 0' }} />
        </View>
        <View style={styles.section}>
          <View style={styles.container}>
            <View style={styles.column}>
              <Text style={[styles.fieldTxt, styles.margin]}>
                EL ARRENDATARIO se compromete a
              </Text>
              {textItems.map((item, index) => (
                <Text key={index} style={styles.boldSmallText}>
                  {item}
                </Text>
              ))}
            </View>
            <View style={styles.column}>
              <Text style={[styles.fieldTxt, styles.margin]}>
                The LESSEE agrees to
              </Text>
              {textItemsEN.map((item, index) => (
                <Text key={index} style={styles.boldSmallText}>
                  {item}
                </Text>
              ))}
            </View>
          </View>
          <hr
            style={{
              border: '1px solid #2596be',
              margin: '0',
              width: '20%',
              left: '45%',
              transform: 'translateY(40%)'
            }}
          />
          <hr
            style={{
              border: '1px solid #2596be',
              margin: '0',
              width: '20%',
              left: '75%',
              transform: 'translateY(37%)'
            }}
          />
          <View style={styles.field}>
            <Text
              style={{
                left: 230,
                transform: 'translateY(50%)',
                ...styles.miniFieldLabel
              }}
            >
              Firma arrendante
            </Text>
            <Text
              style={{
                left: 299,
                transform: 'translateY(50%)',
                ...styles.miniFieldLabel
              }}
            >
              Firma cliente
            </Text>
          </View>
        </View>{' '}
        {/*columnas de arrendatario */}
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.seccionGris}></View>
          <View style={styles.seccion}>
            <Text style={styles.fieldLabelSeccion}>GASTOS EXTRA</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.fieldAlone}>
            <Text style={styles.fieldLabel}>Descripción:</Text>
            <View style={[styles.backColorName, { marginLeft: -60 }]}>
              <Text style={styles.fieldTxt}>{order?.amountDescription}</Text>
            </View>
          </View>
          <View style={styles.multipleField}>
            <View style={styles.field}>
              <Text style={[styles.fieldLabel]}>Gastos extra:</Text>
              <View style={[styles.backColorTransmi, { marginLeft: -50 }]}>
                <Text style={styles.fieldTxt}>{`$${order?.amount}`}</Text>
              </View>
            </View>
            <View style={styles.field}>
              <Text style={[styles.fieldLabel]}>Total:</Text>
              <View style={[styles.backColorTransmi, { marginLeft: -85 }]}>
                <Text style={styles.fieldTxt}>{`$${order?.amount}`}</Text>
              </View>
            </View>
            <View style={styles.field}></View>
            <View style={styles.field}></View>
          </View>
        </View>
        {/*gastos extra */}

        {/*imagen de vehiculo */}
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.seccionGris}></View>
          <View style={styles.seccionCarImg}>
            <Text
              style={[
                styles.fieldLabelSeccion,
                { paddingLeft: -10, fontSize: 15 }
              ]}
            >
              Esquema del vehículo arrendado{'\n'}
              <Text style={[styles.fieldLabelSeccion, { paddingRight: 230 }]}>
                Recibí el vehículo según consta en esta revisión
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.containerImg}>
          {vehicleImage && <Image source={vehicleImage} style={styles.image} />}
        </View>
      </Page>{' '}
      {/*gastos extras e imagen de vehiculo */}
      <Page>
        <Image source={secondPage} />
      </Page>
    </Document>
  );
};

export default ContractDocument;
