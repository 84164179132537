import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roles } from '../../../constants/system';
import { fetchUsers } from '../../../redux/usersSlice';
import Icon from '../../Icons';
import Loader from '../../Loader';
import './styles.css';
const OrderReturnForm = ({ onSubmit, isLoading = false }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const [formData, setFormData] = useState({
    receivedBy: '',
    odometer: '',
    comments: '',
    gasReturn: '',
    file: ''
  });

  const [previewImage, setPreviewImage] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        setPreviewImage(readerEvent.target?.result);
      };
      setFormData((prevData) => ({
        ...prevData,
        file
      }));
    }
  };

  const handleClosePreviewImage = () => {
    setPreviewImage(null);
    setFormData((prevData) => ({
      ...prevData,
      imgCar: null
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const isValid = formData.receivedBy && formData.odometer && formData.comments;

  return (
    <form className="custom-form" onSubmit={handleSubmit}>
      <div className="formTitleWrapper">
        <h2>Orden de retorno</h2>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="brand">
          Recibido Por:
          <select
            className="basic"
            id="receivedBy"
            name="receivedBy"
            onChange={handleChange}
            value={formData.receivedBy}
          >
            <option value="" disabled defaultChecked>
              Seleccionar usuario
            </option>
            {users?.map(
              ({ _id: userId, name, rolId }) =>
                (rolId === roles.admin || rolId?._id === roles.seller) && (
                  <option key={userId} value={userId}>
                    {name}
                  </option>
                )
            )}
          </select>
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="odometer">
          Odometro
          <input
            type="text"
            id="odometer"
            name="odometer"
            value={formData.odometer}
            onChange={handleChange}
            required
            className="input"
            placeholder="2342km"
          />
        </label>
      </div>
      <div className="formGroup">
        <label className="label" htmlFor="gasReturn">
          Gas
          <input
            type="text"
            id="gasReturn"
            name="gasReturn"
            value={formData.gasReturn}
            onChange={handleChange}
            required
            className="input"
            placeholder="3 Gal"
          />
        </label>
      </div>

      <div className="formGroup">
        <label className="label" htmlFor="comments">
          Comentarios
          <input
            type="text"
            id="comments"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="input"
            placeholder="Un pequeño choque en el capo, nada grave"
          />
        </label>
      </div>

      <div className="formGroup formImageGroup">
        <label htmlFor="file" className="uploadLabel">
          <input
            type="file"
            id="file"
            name="file"
            accept="image/*"
            onChange={handleImageChange}
            className="fileInput"
          />
          <span className="uploadText">Subir Imagen</span>
        </label>
        {formData.file && (
          <div className="previewImageWrapper">
            <button className="iconWrapper" onClick={handleClosePreviewImage}>
              <Icon name="exit" width={30} height={30} />
            </button>
            <img src={previewImage} className="previewImage" />
          </div>
        )}
      </div>

      <div className="buttonsWrapper">
        <button
          type="submit"
          className="cardSecondaryButton"
          disabled={!isValid}
        >
          {isLoading ? <Loader stroke="#fff" /> : <span>Guardar</span>}
        </button>
      </div>
    </form>
  );
};

export default OrderReturnForm;
