import { configureStore } from '@reduxjs/toolkit';
import carsReducer from './carsSlice';
import authSlice from './authSlice';
import sellerReducer from './sellersSlice';
import usersReducer from './usersSlice';
import reservationOrdersSlice from './reservationOrdersSlice';
import returnOrdersSlice from './returnOrdersSlice';
import options from './options';
import summary from './summary';

const store = configureStore({
  reducer: {
    car: carsReducer,
    auth: authSlice,
    reservationOrder: reservationOrdersSlice,
    seller: sellerReducer,
    user: usersReducer,
    returnOrder: returnOrdersSlice,
    options: options,
    summary: summary
  }
});

export default store;
