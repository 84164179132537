/* eslint-disable */
export const getOrderReturnGraph = (params = null) => {
  return {
    query: `
      query OrderReturn($orderReturnId: String, $odometer: String, $orderId: String, $receivedBy: String) {
        OrderReturn(id: $orderReturnId, odometer: $odometer, orderId: $orderId, receivedBy: $receivedBy) {
          isSuccess
          message
          result {
            _id
            comments
            imgReference
            odometer
            gasReturn
            orderId {
              _id
              startDate
              endDate
            }
            receivedBy {
              _id
              name
              email
              rolId {
                _id
                name
                rol
              }
              number
            }
          }
          error
        }
      }`,
    ...(params && { variables: params })
  };
};
