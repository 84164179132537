/* eslint-disable */
export const getTypeVehicleGraph = (params = null) => {
  return {
    query: `
    query TypeVehicle($name: String, $typeVehicleId: String) {
      TypeVehicle(name: $name, id: $typeVehicleId) {
        isSuccess
        message
        result {
          _id
          name
        }
        error
      }
    }`,
    ...(params && { variables: params })
  };
};
