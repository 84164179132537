import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { queryToGraph } from '../helper/graphRequest';
import { getUserGraph } from '../graphql/User';
import _ from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';

// const baseURL = 'https://carrental-9ijm.onrender.com/users';
const baseURL = 'https://rent-car-backend-ecxw.onrender.com/api';

const initialState = {
  sellers: [],
  status: 'idle',
  isLoading: false,
  isModalOpen: false,
  error: ''
};

export const fetchSellers = createAsyncThunk(
  'sellers/fetchsellers',
  async () => {
    const response = await queryToGraph(getUserGraph());
    if (_.get(response, 'User.isSuccess', false)) {
      return _.get(response, 'User.result', []);
    }
    return _.get(response, 'User.message', '');
  }
);

export const addSeller = createAsyncThunk(
  'sellers/addSeller',
  async ({ sellerDetails }) => {
    const response = await fetch(`${baseURL}/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: sellerDetails.name,
        email: sellerDetails.email,
        number: sellerDetails.number,
        password: sellerDetails.password,
        rolId: sellerDetails.rolId
      })
    });
    const data = await response.json();
    return data;
  }
);

export const updateSeller = createAsyncThunk(
  'sellers/updateSeller',
  async ({ _id, name, email, number, password, rolId }) => {
    try {
      const response = await axios.patch(`${baseURL}/user/${_id}`, {
        name,
        email,
        number,
        password,
        rolId
      });
      return { apiRes: response.data, _id };
    } catch (error) {
      throw new Error(error.response.data.errors || 'Update failed');
    }
  }
);

export const deleteSeller = createAsyncThunk(
  'sellers/deleteSeller',
  async ({ sellerId }) => {
    await fetch(`${baseURL}/user/${sellerId}`, {
      method: 'DELETE'
    });
    return sellerId;
  }
);

const sellersSlice = createSlice({
  name: 'sellers',
  initialState,
  reducers: {
    // Add a reducer to reset status after a certain time
    resetStatus: (state) => ({
      ...state,
      status: 'idle'
    }),
    openModal: (state) => ({
      ...state,
      isModalOpen: true
    }),
    closeModal: (state) => ({
      ...state,
      isModalOpen: false
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSellers.pending, (state) => ({
        ...state,
        status: 'fetching',
        isLoading: true
      }))
      .addCase(fetchSellers.fulfilled, (state, action) => ({
        ...state,
        status: 'success',
        isLoading: false,
        sellers: action.payload
      }))
      .addCase(fetchSellers.rejected, (state) => ({
        ...state,
        status: 'error',
        isLoading: false
      }))
      .addCase(addSeller.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'adding'
      }))
      .addCase(addSeller.fulfilled, (state, action) => {
        // Check if there are no errors
        if (action.payload.isSuccess) {
          toast.success('Vendedor creado!');
          return {
            ...state,
            status: 'added successfully',
            isLoading: false,
            isModalOpen: false,
            sellers: [action.payload.result, ...state.sellers],
            error: '' // Clear any previous errors
          };
        }
        toast.error('Hubo un error, intente más tarde');
        return {
          ...state,
          status: 'error',
          isLoading: false,
          error: action.payload.message
        };
      })
      .addCase(addSeller.rejected, (state) => ({
        ...state,
        status: 'error',
        isLoading: false
      }))
      .addCase(updateSeller.pending, (state) => ({
        ...state,
        status: 'updating',
        isLoading: true
      }))
      .addCase(updateSeller.fulfilled, (state, action) => {
        if (action.payload.apiRes?.isSuccess) {
          toast.success('Vendedor actualizado!');
          return {
            ...state,
            status: 'updated successfully',
            isModalOpen: false,
            isLoading: false,
            sellers: state.sellers.map((seller) =>
              seller._id === action.payload._id
                ? { ...action.payload.apiRes.result }
                : seller
            )
          };
        }

        toast.error('El vendedor no pudo ser actualizado, intente más tarde');

        return {
          ...state,
          status: 'error',
          isLoading: false,
          error: action.payload.apiRes?.message
        };
      })
      .addCase(updateSeller.rejected, (state, action) => ({
        ...state,
        status: 'error-updating',
        error: action.error.message
      }))
      .addCase(deleteSeller.pending, (state) => ({
        ...state,
        status: 'deleting',
        isLoading: true
      }))
      .addCase(deleteSeller.fulfilled, (state, action) => {
        toast.success('Vendedor eliminado!');
        return {
          ...state,
          status: 'removed successfully',
          isLoading: false,
          sellers: state.sellers.filter(
            (seller) => seller._id !== action.payload
          )
        };
      })
      .addCase(deleteSeller.rejected, (state) => {
        toast.error('Hubo un error, intente más tarde');
        return {
          ...state,
          status: 'error-deleting',
          isLoading: false
        };
      });
  }
});

export const { resetStatus, openModal, closeModal } = sellersSlice.actions;
export default sellersSlice.reducer;
